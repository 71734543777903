import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <div className="container mx-auto px-2 py-8 xl:px-0 text-center">
            <h1 className="text-4xl">404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </Layout>
)

export default NotFoundPage
